import { Fragment, FunctionComponent, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import CallOut1 from 'design-system/Typography/CallOut/CallOut1'
import Heading2 from 'design-system/Typography/Heading/Heading2'
import Subheading1 from 'design-system/Typography/Subheading/Subheading1'

import BackgroundImage from 'components/BackgroundImage'
import Spacer from 'components/Spacer'
import ViewportMarginContainer from 'components/ViewportMarginContainer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 32px 0;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    padding: 48px 0;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    padding: 72px 0;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    flex-direction: row;
  }
`

interface ITextAlign {
  textAlignDesktop?: 'center' | 'left' | 'right'
}
const ResultsContainer = styled.div<ITextAlign>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    display: none;
    text-align: left;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    display: flex;
    ${props => props.textAlignDesktop && `text-align: ${props.textAlignDesktop};`}
  }
`

const ResultsContainerTabletOnly = styled(ResultsContainer)`
  display: none;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    display: flex;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    display: none;
  }
`

const ResultWrapper = styled.div`
  padding: 0;
  margin: 0;
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  order: 0;
  border-radius: ${props => props.theme.radii[24]}px;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    width: 50%;
    height: 600px;
    order: -1;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    width: 500px;
    height: 400px;
    order: 0;
  }
`

const Results: FunctionComponent = () => {
  const theme = useContext(ThemeContext)

  const results = [
    { value: '$77.5 Million', label: 'Saved Commissions to Date' },
    { value: 'Over 3,750', label: 'Happy Homesellers' },
    { value: '4.8/5', label: 'Rating on Zillow' },
    { value: '9 Days', label: 'Median Time on Market' },
    { value: '$21,000', label: 'Average Savings Per Sale' },
    { value: '$1.9 Billion', label: 'Total Transactions' },
  ]

  const renderResult = (result: { value: string; label: string }) => (
    <ResultWrapper>
      <CallOut1 color="gold600">{result.value}</CallOut1>
      <Subheading1 color="neutral300">{result.label}</Subheading1>
    </ResultWrapper>
  )

  return (
    <ViewportMarginContainer center>
      <Container>
        <Heading2 textAlign="center">Thousands of happy homesellers. Millions saved!</Heading2>
        <Spacer
          size={theme.spacings[56]}
          tabletSize={theme.spacings[40]}
          hideMobile
        />
        <ContentContainer>
          <ResultsContainer textAlignDesktop="right">
            {results.slice(0, 3).map(result => (
              <Fragment key={result.label}>
                <Spacer
                  size={theme.spacings[32]}
                  hideTablet
                  hideDesktop
                />
                {renderResult(result)}
              </Fragment>
            ))}
          </ResultsContainer>
          <Spacer
            size={theme.spacings[32]}
            hideTablet
          />
          <ImageContainer>
            <BackgroundImage
              backgroundSize="cover"
              imgSrc={`${process.env.CDN_BASE_URL}/w_1200,q_auto:best,dpr_auto,f_auto/amazon/assets/hp-b/smiling-family-holding-a-paper-roof.jpg`}
            />
          </ImageContainer>
          <Spacer
            size={theme.spacings[32]}
            tabletSize={theme.spacings[72]}
            hideMobile
          />
          <ResultsContainer textAlignDesktop="left">
            {results.slice(3).map(result => (
              <Fragment key={result.label}>
                <Spacer
                  size={theme.spacings[32]}
                  hideTablet
                  hideDesktop
                />
                {renderResult(result)}
              </Fragment>
            ))}
          </ResultsContainer>
          <ResultsContainerTabletOnly>{results.map(renderResult)}</ResultsContainerTabletOnly>
        </ContentContainer>
      </Container>
    </ViewportMarginContainer>
  )
}

export default Results
