import { FunctionComponent, RefObject, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import StandardButton from 'design-system/Buttons/StandardButton'
import Body2 from 'design-system/Typography/Body/Body2'
import Heading2 from 'design-system/Typography/Heading/Heading2'

import BackgroundImage from 'components/BackgroundImage'
import Spacer from 'components/Spacer'
import ViewportMarginContainer from 'components/ViewportMarginContainer'

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    flex-direction: row;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  border-radius: ${props => props.theme.radii[24]}px;
  box-shadow: ${props => props.theme.shadows.neutral.level4};
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    height: 300px;
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    height: 440px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    max-width: 472px;
  }
`

const StyledSpan = styled.span`
  font-weight: ${props => props.theme.fonts.weights.heavy};
`
interface IProps {
  introductionRef?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined
}
const Introduction: FunctionComponent<IProps> = ({ introductionRef }) => {
  const theme = useContext(ThemeContext)

  return (
    <ViewportMarginContainer center>
      <Spacer
        size={theme.spacings[48]}
        tabletSize={theme.spacings[56]}
        desktopSize={theme.spacings[72]}
      />
      <LayoutContainer ref={introductionRef}>
        <ImageContainer>
          <BackgroundImage
            backgroundSize="cover"
            imgSrc={`${process.env.CDN_BASE_URL}/w_1200,q_auto:best,dpr_auto,f_auto,a_hflip/amazon/assets/hp-b/smiling-parents-with-a-baby-girl.jpg`}
          />
        </ImageContainer>
        <Spacer
          size={theme.spacings[36]}
          tabletSize={theme.spacings[40]}
          desktopSize={theme.spacings[72]}
        />
        <ContentContainer>
          <Heading2 textAlign="center">Don&apos;t pay 6% commission fees.</Heading2>
          <Spacer size={theme.spacings[36]} />
          <Body2
            color="neutral500"
            textAlign="center"
          >
            Thanks to modern technology, buyers and sellers no longer need agents to determine home
            value, search for homes, or find comparable sales. And yet traditional real estate
            agents still want you to pay <StyledSpan> 6% of your equity </StyledSpan> to them like
            you did before the internet.
          </Body2>
          <Spacer size={theme.spacings[36]} />
          <Body2
            color="neutral500"
            textAlign="center"
          >
            At HomeLister, we want you to keep that equity, have a better experience, and get all
            the benefits that come with an agent - all for a low flat fee.
          </Body2>
          <Spacer size={theme.spacings[36]} />
          <StandardButton
            label="Get Started"
            type="primary"
            size="medium"
            href="/listing/create"
          />
        </ContentContainer>
      </LayoutContainer>
      <Spacer
        size={theme.spacings[32]}
        tabletSize={theme.spacings[48]}
        desktopSize={theme.spacings[72]}
      />
    </ViewportMarginContainer>
  )
}

export default Introduction
