import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { ThemeContext } from 'styled-components'

import StandardButton from 'design-system/Buttons/StandardButton'
import { Grid, GridItem } from 'design-system/Layouts/Grid'
import Body2 from 'design-system/Typography/Body/Body2'
import Heading2 from 'design-system/Typography/Heading/Heading2'
import Heading4 from 'design-system/Typography/Heading/Heading4'
import Title1 from 'design-system/Typography/Title/Title1'

import Image from 'components/Image'
import ImageContainer from 'components/ImageContainer'
import { CheckmarkThin } from 'components/SVG/Checkmark'
import Spacer from 'components/Spacer'
import ViewportMarginContainer from 'components/ViewportMarginContainer'

import { useWindowSize } from 'services/hooks'

import { HowItWorksDataHomePage as HowItWorksData } from 'data/HowItWorksData'

interface ISelectedProp {
  selected?: boolean
}

const ListContainer = styled.div``

const List = styled.div<ISelectedProp>`
  display: flex;
  align-items: flex-start;
  svg {
    fill: ${props => (props.selected ? props.theme.colors.gold500 : props.theme.colors.neutral500)};
    flex-shrink: 0;
  }
`

/* progress bar styled components */

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Circle = styled.div<ISelectedProp>`
  background-color: ${props =>
    props.selected ? props.theme.colors.blue500 : props.theme.colors.white};
  border: 3px solid ${props => props.theme.colors.blue500};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.blue500)};
    padding-top: 2px;
  }

  transition: background-color 100ms ease-out;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    width: 54px;
    height: 54px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    width: 62px;
    height: 62px;
  }
`

interface IEdgeLineProp {
  edgeLine?: boolean
}

interface IResponsiveProp {
  responsive?: boolean
}

const ProgressLine = styled.div<IEdgeLineProp & IResponsiveProp>`
  width: 5px;
  height: ${props => (props.responsive ? '25%' : '100%')};
  background-color: ${props =>
    props.edgeLine ? props.theme.colors.white : props.theme.colors.blue500};

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    height: ${props => (props.responsive ? '50%' : '100%')};
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    height: 100%;
    background-color: ${props => props.theme.colors.blue500};
  }
`
interface IRefsProps {
  [key: string]: (node?: Element | null | undefined) => void
}

interface IProgressBarProps {
  refs: IRefsProps
  inViewIndex: number
}

const ProgressBar: FunctionComponent<IProgressBarProps> = ({ refs, inViewIndex }) => {
  const { step1Ref, step2Ref, step3Ref } = refs
  return (
    <ProgressContainer>
      <ProgressLine
        responsive
        edgeLine
      />
      <Circle
        ref={step1Ref}
        selected={inViewIndex === 0}
      >
        <Title1>01</Title1>
      </Circle>
      <ProgressLine />
      <ProgressLine responsive />
      <Circle
        ref={step2Ref}
        selected={inViewIndex === 1}
      >
        <Title1>02</Title1>
      </Circle>
      <ProgressLine />
      <ProgressLine responsive />
      <Circle
        ref={step3Ref}
        selected={inViewIndex === 2}
      >
        <Title1>03</Title1>
      </Circle>
      <ProgressLine edgeLine />
    </ProgressContainer>
  )
}

const DesktopView: FunctionComponent<IProgressBarProps> = ({ refs, inViewIndex }) => (
  <Grid
    gridColumns="1fr 64px 1fr"
    columnGap="96px"
  >
    <Grid
      gridColumns="1fr"
      gridRows="repeat(3, 1fr)"
      rowGap="96px"
    >
      <GridItem>
        <ImageContainer borderRadius="24px">
          <Image
            imgSrc={`${process.env.CDN_BASE_URL}/q_auto,w_1024/${HowItWorksData[0].imgUrl}`}
            alt={HowItWorksData[0].title}
          />
        </ImageContainer>
      </GridItem>
      <GridItem>
        <Heading4 color={inViewIndex === 1 ? 'blue600' : 'neutral900'}>
          {HowItWorksData[1].title}
        </Heading4>
        {HowItWorksData[1].details.map(list => (
          <Fragment key={list}>
            <Spacer size={16} />
            <List selected={inViewIndex === 1}>
              <CheckmarkThin
                width={24}
                height={24}
              />
              <Spacer
                responsiveSize={4}
                tabletSize={8}
                size={24}
              />
              <Body2 color="neutral500">{list}</Body2>
            </List>
          </Fragment>
        ))}
      </GridItem>
      <GridItem>
        <ImageContainer borderRadius="24px">
          <Image
            imgSrc={`${process.env.CDN_BASE_URL}/q_auto,w_1024/${HowItWorksData[2].imgUrl}`}
            alt={HowItWorksData[2].title}
          />
        </ImageContainer>
      </GridItem>
    </Grid>
    <ProgressBar
      refs={refs}
      inViewIndex={inViewIndex}
    />
    <Grid
      gridColumns="1fr"
      gridRows="repeat(3, 1fr)"
      rowGap="96px"
    >
      <GridItem>
        <Heading4 color={inViewIndex === 0 ? 'blue600' : 'neutral900'}>
          {HowItWorksData[0].title}
        </Heading4>
        {HowItWorksData[0].details.map(list => (
          <Fragment key={list}>
            <Spacer size={16} />
            <List selected={inViewIndex === 0}>
              <CheckmarkThin
                width={24}
                height={24}
              />
              <Spacer
                responsiveSize={4}
                tabletSize={8}
                size={24}
              />
              <Body2 color="neutral500">{list}</Body2>
            </List>
          </Fragment>
        ))}
      </GridItem>
      <GridItem>
        <ImageContainer borderRadius="24px">
          <Image
            imgSrc={`${process.env.CDN_BASE_URL}/q_auto,w_1024/${HowItWorksData[1].imgUrl}`}
            alt={HowItWorksData[1].title}
          />
        </ImageContainer>
      </GridItem>
      <GridItem>
        <Heading4 color={inViewIndex === 2 ? 'blue600' : 'neutral900'}>
          {HowItWorksData[2].title}
        </Heading4>
        {HowItWorksData[2].details.map(list => (
          <Fragment key={list}>
            <Spacer size={16} />
            <List selected={inViewIndex === 2}>
              <CheckmarkThin
                width={24}
                height={24}
              />
              <Spacer
                responsiveSize={4}
                tabletSize={8}
                size={24}
              />
              <Body2 color="neutral500">{list}</Body2>
            </List>
          </Fragment>
        ))}
      </GridItem>
    </Grid>
  </Grid>
)

const HowItWorks: FunctionComponent = () => {
  const theme = useContext(ThemeContext)
  const { width } = useWindowSize()
  const isDesktopView = width && width >= theme.metrics.desktop

  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  const { ref: step1Ref, inView: step1InView } = useInView({ threshold: 1, rootMargin: '-25% 0px' })
  const { ref: step2Ref, inView: step2InView } = useInView({ threshold: 1, rootMargin: '-25% 0px' })
  const { ref: step3Ref, inView: step3InView } = useInView({ threshold: 1, rootMargin: '-25% 0px' })

  const refs: IRefsProps = { step1Ref, step2Ref, step3Ref }
  const inViews = [step1InView, step2InView, step3InView]
  const [inViewIndex, setInViewIndex] = useState(-1)

  useEffect(() => {
    setInViewIndex(inViews.lastIndexOf(true))
  }, [inViews])

  if (!mounted) return null

  return (
    <ViewportMarginContainer center>
      <Spacer
        size={theme.spacings[32]}
        tabletSize={theme.spacings[48]}
        desktopSize={theme.spacings[72]}
      />
      <Heading2 textAlign="center">How it Works</Heading2>
      <Spacer
        size={theme.spacings[36]}
        tabletSize={theme.spacings[40]}
        desktopSize={theme.spacings[56]}
      />
      <>
        {isDesktopView
          ? (
            <DesktopView
              refs={refs}
              inViewIndex={inViewIndex}
            />
          )
          : (
            <Grid
              gridColumnsMobile="40px 32px 1fr"
              gridColumns="56px 80px 1fr"
              columnGap="0px"
            >
              <ProgressBar
                refs={refs}
                inViewIndex={inViewIndex}
              />
              <Spacer
                responsiveSize={32}
                size={80}
              />
              <Grid
                gridColumns="1fr"
                rowGap="56px"
              >
                {HowItWorksData.map((data, index) => (
                  <GridItem key={data.title}>
                    <ImageContainer borderRadius="24px">
                      <Image
                        imgSrc={`${process.env.CDN_BASE_URL}/q_auto,w_1024/${data.imgUrl}`}
                        alt={data.title}
                      />
                    </ImageContainer>
                    <Spacer size={theme.spacings[32]} />
                    <ListContainer>
                      <Heading4 color={inViewIndex === index ? 'blue600' : 'neutral900'}>
                        {data.title}
                      </Heading4>
                      {data.details.map(list => (
                        <Fragment key={list}>
                          <Spacer size={16} />
                          <List selected={inViewIndex === index}>
                            <CheckmarkThin
                              width={24}
                              height={24}
                            />
                            <Spacer
                              responsiveSize={8}
                              tabletSize={8}
                              size={24}
                            />
                            <Body2 color="neutral500">{list}</Body2>
                          </List>
                        </Fragment>
                      ))}
                    </ListContainer>
                  </GridItem>
                ))}
              </Grid>
            </Grid>
          )}
        <Spacer
          size={theme.spacings[36]}
          tabletSize={theme.spacings[40]}
          desktopSize={theme.spacings[56]}
        />
        <StandardButton
          label="Learn More"
          type="primary"
          size="medium"
          href="/how-it-works"
          center
        />
        <Spacer
          size={theme.spacings[32]}
          tabletSize={theme.spacings[48]}
          desktopSize={theme.spacings[72]}
        />
      </>
    </ViewportMarginContainer>
  )
}

export default HowItWorks
